/* eslint-disable id-denylist */
import { HttpService } from 'http/httpService'
import { LoggingService, EventType, ActionEventData } from 'http/loggingService'
import {
    TransactionsQueryResponse,
    SelectedForRefund,
    Order,
    OrderDetailsPageData,
    GetOrderDetailsRestParams,
    BulkRefundOrderProps,
    Cart,
    OrdersListRequestPayload,
    OrdersListResult,
    IOrder,
    SentEmailsResponse,
} from './schema'
import { DateRangeType } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import { Result, success, failure } from 'result'
import {
    transactionsQuery,
    barcodesQuery,
    orderRefundQuery,
    apfItemUpdateQuery,
    ticketValidToUpdateQuery,
} from './queries'
import { cancelOutOffsetBeforeConvertionToUTC } from 'utils'
import { parseSearch } from 'navigation'
import { ProductsMetaData, Article } from 'uiComponents/filter/schema'
import { Locale, nestedProductListQueryFragment } from 'admin/articleService'
import { CursorPaginatedResponse } from '../../types'
import { OrderRefundDetails } from './orders/commons/orderTicketsActions/cancelAndRefund/types'
import { UseDownloadLinkPayload } from './orders/utils'
import { DeepPartial } from 'utility-types'
import { convertToSnakeCase } from 'utils/camelToSnake'
import { ExportReservationsPayload } from './cart/export/cartExportModal'

type SearchType = 'simple' | 'extended'

interface CreateExceptionServerError {
    type: 'server_error'
    statusCode: number
}

export interface BarcodesContext {
    date: string | null
    price: string | null
    type: string | null
    redeemed_at: string | null
    already_redeemed: boolean
}

export interface RedeemSuccess {
    type: 'redeem_success'
    barcodes: BarcodesContext[]
}

export interface CreateExceptionBarcodeError {
    type: 'barcode_error'
    notFound: string
    alreadyRedeemed: string
    alreadyScannedIn: string
    notScannableCode: string
    scanInRequired: string
    timeLimitReached: string
    futureValidity: string
    pastValidity: string
    barcodes: BarcodesContext[]
    error: string
}

export interface Question {
    articleUuid: string
    articleName: string
    questionText: string
    questionType: 'number' | 'text'
    questionDescription: string
    answerMinimum?: number
    answerMaximum?: number
}

export interface GetQuestionsSuccess {
    type: 'get_questions_success'
    questions: Question[]
    orderUuid: string
}

export interface GetQuestionsError {
    type: 'get_questions_error'
    code?: string
    message?: string
    barcode?: string
}

export interface GroupArticleToRedeem {
    article_uuid: string
    amount_to_redeem: number
}

export interface GroupRedeemSuccess {
    type: 'group_redeem_success'
    barcodes: any
}

export interface GroupRedeemError {
    type: 'group_redeem_error'
    barcodes?: Record<
        string,
        {
            date: string
            price: number
            type: string
            redeemed_at: string | null
            already_redeemed: boolean
        }
    >
    message?: string
    statusCode?: number
}

export interface UploadedFile {
    file_id: string
    file_name: string
}

export interface Location {
    name: string
    uuid: string
}

export interface AccountDataForFilter {
    productsLists: ProductsMetaData[]
    locales: Locale[]
    locations: Location[]
}

export interface BulkOrderRefundErrors {
    noRefundableItems: string[]
    noPaymentInfo: string[]
    invalidRequest: string[]
}

export interface BulkOrderRefundResponse {
    error: BulkOrderRefundErrors
    processed: string[]
}

export interface ScheduleAsyncExportPayload {
    search?: string | null
    account_slug: string
    export_format: string
    timezone_offset: number
    date_from: string | null
    date_to: string | null
}

type CreateExceptionError = CreateExceptionServerError | CreateExceptionBarcodeError

export class OrdersService {
    constructor(private httpService: HttpService, private loggingService: LoggingService, private endpoint: string) {}

    redeemBarcodes = async (
        accountSlug: string,
        barcodes: string[],
    ): Promise<Result<RedeemSuccess, CreateExceptionError>> => {
        const logEventType: EventType = 'order_barcodes_redeemed'
        const logEventData: ActionEventData = {
            category: 'orders_details',
            payload: { barcodes },
        }

        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/accounts/${accountSlug}/barcodes/redeem/`,
            {
                method: 'POST',
                body: JSON.stringify({ barcodes }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        const body = await response.json()
        if (response.status === 400) {
            this.loggingService.logError(body, logEventType, logEventData)
            return failure({
                type: 'barcode_error',
                notFound: body.error.context.not_found?.join(', '),
                alreadyRedeemed: body.error.context.already_redeemed?.join(', '),
                alreadyScannedIn: body.error.context.already_scanned_in?.join(', '),
                notScannableCode: body.error.context.not_scannable_code?.join(', '),
                scanInRequired: body.error.context.scan_in_required?.join(', '),
                timeLimitReached: body.error.context.time_limit_reached?.join(', '),
                futureValidity: body.error.context.future_validity?.join(', '),
                pastValidity: body.error.context.past_validity?.join(', '),
                barcodes: body.barcodes,
                error: body.error?.message,
            } as CreateExceptionError)
        } else if (response.status !== 200) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            return failure({
                type: 'server_error',
                statusCode: response.status,
            })
        }
        this.loggingService.logAction(logEventType, logEventData)
        return success({
            type: 'redeem_success',
            barcodes: body.barcodes,
        })
    }

    redeemBarcodesWithAuthToken = async (
        accountSlug: string,
        barcodes: string[],
        token: string,
    ): Promise<Result<RedeemSuccess, CreateExceptionError>> => {
        const logEventType: EventType = 'order_barcodes_redeemed_from_scanner'
        const logEventData: ActionEventData = {
            category: 'orders_details',
            payload: { barcodes },
        }
        const response = await this.httpService.fetchAnonymous(
            `${this.endpoint}api/v1/accounts/${accountSlug}/barcodes/redeem/`,
            {
                method: 'POST',
                body: JSON.stringify({ barcodes }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            },
        )
        const body = await response.json()
        if (response.status === 400) {
            this.loggingService.logError(body, logEventType, logEventData)
            return failure({
                type: 'barcode_error',
                notFound: body.error.context.not_found?.join(', '),
                alreadyRedeemed: body.error.context.already_redeemed?.join(', '),
                alreadyScannedIn: body.error.context.already_scanned_in?.join(', '),
                notScannableCode: body.error.context.not_scannable_code?.join(', '),
                scanInRequired: body.error.context.scan_in_required?.join(', '),
                timeLimitReached: body.error.context.time_limit_reached?.join(', '),
                futureValidity: body.error.context.future_validity?.join(', '),
                pastValidity: body.error.context.past_validity?.join(', '),
                barcodes: body.barcodes,
            } as CreateExceptionError)
        } else if (response.status !== 200) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            return failure({
                type: 'server_error',
                statusCode: response.status,
            })
        }
        this.loggingService.logAction(logEventType, logEventData)

        return success({
            type: 'redeem_success',
            barcodes: body.barcodes,
        })
    }

    groupRedeemBarcodes = async (
        accountSlug: string,
        barcode: string,
        articlesToRedeem: GroupArticleToRedeem[],
    ): Promise<Result<GroupRedeemSuccess, GroupRedeemError>> => {
        const payload = {
            barcode: barcode,
            articles_to_redeem: articlesToRedeem,
        }

        const logEventType: EventType = 'group_order_barcodes_redeemed'
        const logEventData: ActionEventData = {
            category: 'orders_details',
            payload,
        }

        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/accounts/${accountSlug}/barcodes/redeem-group/`,
            {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        const body = await response.json()
        if (response.status === 400) {
            this.loggingService.logError(body, logEventType, logEventData)
            return failure({
                type: 'group_redeem_error',
                message: body.error.message,
                barcodes: body.barcodes,
            } as GroupRedeemError)
        } else if (response.status !== 200) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            return failure({
                type: 'group_redeem_error',
                statusCode: response.status,
            })
        }
        this.loggingService.logAction(logEventType, logEventData)
        return success({
            type: 'group_redeem_success',
            barcodes: body.barcodes,
        } as GroupRedeemSuccess)
    }

    getQuestions = async (
        accountSlug: string,
        barcode: String,
    ): Promise<Result<GetQuestionsSuccess, GetQuestionsError>> => {
        const url = `${this.endpoint}api/v1/accounts/${accountSlug}/barcode/questions/`
        const payload = { barcode }
        const response = await this.httpService.fetchAnonymous(url, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const questions = await response.json()
        if (questions.error) {
            return failure({
                type: 'get_questions_error',
                code: questions.error.code,
                message: questions.error.message,
            } as GetQuestionsError)
        } else {
            return success({
                type: 'get_questions_success',
                questions: questions?.orders[0].questions,
                orderUuid: questions?.orders[0].orderUuid,
            } as GetQuestionsSuccess)
        }
    }

    async resendEmail(orderNumber: string): Promise<void> {
        const logEventType: EventType = 'confirmation_email_resent'
        const logEventData: ActionEventData = {
            category: 'orders_transactions',
            payload: { orderNumber },
        }

        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/cart/order/${orderNumber}/confirmation_email/`,
            {
                method: 'POST',
            },
        )
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async updateEmail(orderUuid: string, email: string): Promise<void> {
        const logEventType: EventType = 'confirmation_email_updated'
        const logEventData: ActionEventData = {
            category: 'orders_transactions',
            payload: { orderUuid, newEmail: email },
        }

        const response = await this.httpService.fetch(`${this.endpoint}api/v1/orders/${orderUuid}/`, {
            method: 'PATCH',
            body: JSON.stringify({ email }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async getSentOrderEmails({ id, accountSlug }: { id: string; accountSlug: string }): Promise<SentEmailsResponse> {
        const logEventType: EventType = 'get_sent_emails'
        const logEventData: ActionEventData = {
            category: 'order_details_rest',
            payload: { id },
        }

        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/accounts/${accountSlug}/orders/${id}/sent_emails/`,
            {
                method: 'GET',
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async getSentCartEmails({ id, accountSlug }: { id: string; accountSlug: string }): Promise<SentEmailsResponse> {
        const logEventType: EventType = 'get_sent_emails'
        const logEventData: ActionEventData = {
            category: 'cart',
            payload: { id, accountSlug },
        }

        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/accounts/${accountSlug}/carts/${id}/sent_emails/`,
            {
                method: 'GET',
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    getOrderDetailsRest = async ({ uuid, include = [] }: GetOrderDetailsRestParams): Promise<IOrder> => {
        const logger = this.loggingService.createLogHttpResponse('order_details_rest', '', {
            category: 'order_details_rest',
            payload: {
                uuid,
                include,
            },
        })

        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/orders/${uuid}/?include=${include.join(',')}`,
            {},
        )
        const data = await response.json()
        logger(response)

        return data
    }

    async updateVisitDateAndTime(orderUuid: string, visitDate: string | null, visitTime: string | null): Promise<void> {
        const logEventType: EventType = 'order_visit_date_or_time_updated'
        const logEventData: ActionEventData = {
            category: 'orders_transactions',
            payload: { orderUuid, visitDate, visitTime },
        }

        const response = await this.httpService.fetch(`${this.endpoint}api/v1/orders/${orderUuid}/visit_time/`, {
            method: 'PATCH',
            body: JSON.stringify({ visit_date: visitDate, visit_time: visitTime }),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            const payload = await response.json()
            this.loggingService.logError(payload, logEventType, logEventData)
            if (payload.error.code === 'no_seats_available') {
                throw new Error('no_availability')
            } else if (payload.error.code === 'order_already_redeemed') {
                throw new Error('order_already_redeemed')
            } else if (payload.error.code === 'date_change_not_possible') {
                throw new Error('date_change_not_possible')
            }
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async getTransactionsList(
        widget: string,
        from: Date | string,
        to: Date | string,
        dateRangeType: DateRangeType,
        search: string | undefined,
        sortBy: string,
        sortDirection: string,
        page: number,
        pageSize: number,
        searchType: SearchType,
        filters: string,
    ): Promise<TransactionsQueryResponse> {
        const dateFrom = typeof from === 'string' ? from : cancelOutOffsetBeforeConvertionToUTC(from)
        const dateTo = typeof to === 'string' ? to : cancelOutOffsetBeforeConvertionToUTC(to)

        const body = JSON.stringify({
            query: transactionsQuery,
            variables: {
                widget,
                dateFrom,
                dateTo,
                dateRangeType,
                search,
                sortBy,
                sortDirection,
                page,
                pageSize,
                searchType,
                filters: 'order_source:countr,checkout,api,gyg,prioticket;' + String(filters ?? ''),
            },
            operationName: 'Transactions',
        })
        const response = await this.httpService.fetch(`${this.endpoint}graphql/`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        if (payload.errors) {
            throw new Error(`Backoffice has returned an error: ${payload.errors[0].message}`)
        }
        return payload.data.account
    }

    async getOrdersList({
        accountSlug,
        include,
        sortBy,
        sortDirection,
        search,
        searchBy,
        resellers,
        directSales,
        paymentMethods,
        locations,
        fromCreatedAt,
        toCreatedAt,
        fromEventDate,
        toEventDate,
        status,
        paymentStatus,
        emailStatus,
        products,
        pageSize,
        offset,
    }: OrdersListRequestPayload): Promise<OrdersListResult> {
        const getDateParams = () => {
            const filterByDate = fromEventDate && toEventDate ? 'event_date' : 'created_at'

            return convertToSnakeCase({
                filterByDate,
                dateFrom: filterByDate === 'event_date' ? fromEventDate : fromCreatedAt,
                dateTo: filterByDate === 'event_date' ? toEventDate : toCreatedAt,
            })
        }

        const data = {
            include: include,
            sort_by: sortBy,
            sort_direction: sortDirection,
            search: search,
            search_by: searchBy,
            resellers: resellers,
            direct_sales: directSales ? 'true' : 'false',
            payment_methods: paymentMethods,
            locations: locations,
            status: status,
            payment_status: paymentStatus,
            email_status: emailStatus,
            products: products,
            ...getDateParams(),
        }
        const params = {
            page_size: pageSize.toString(),
            offset: offset,
        }

        const queryParams = new URLSearchParams(params)
        const body = Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== null && value !== ''))
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/accounts/${accountSlug}/orders_list/?${queryParams}`,
            {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        )

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        if (payload.errors) {
            throw new Error(`Backoffice has returned an error: ${payload.errors[0].message}`)
        }

        return payload
    }

    async getOrderDetailsList(
        widget: string,
        from: Date,
        to: Date,
        dateRangeType: DateRangeType,
        search: string | undefined,
        sortBy: string,
        sortDirection: string,
        page: number,
        pageSize: number,
        searchType: SearchType,
        filters: string,
    ): Promise<OrderDetailsPageData> {
        const dateFrom = cancelOutOffsetBeforeConvertionToUTC(from)
        const dateTo = cancelOutOffsetBeforeConvertionToUTC(to)

        const body = JSON.stringify({
            query: barcodesQuery,
            variables: {
                widget,
                dateFrom,
                dateTo,
                dateRangeType,
                search,
                sortBy,
                sortDirection,
                page,
                pageSize,
                searchType,
                filters,
            },
            operationName: 'Barcodes',
        })

        const response = await this.httpService.fetch(`${this.endpoint}graphql/`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        if (payload.errors) {
            throw new Error(`Backoffice has returned an error: ${payload.errors[0].message}`)
        }
        return payload.data.account
    }

    async getOrderDetailsForRefundOld(widget: string, orderNumber: string): Promise<Order> {
        const body = JSON.stringify({
            query: orderRefundQuery,
            variables: { widget, number: orderNumber },
            operationName: 'OrderRefundDetails',
        })

        const response = await this.httpService.fetch(`${this.endpoint}graphql/`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        if (payload.errors) {
            throw new Error(`Backoffice has returned an error: ${payload.errors[0].message}`)
        }
        return payload.data.account.order
    }

    async getOrderDetailsForRefund(orderId: string): Promise<OrderRefundDetails> {
        const response = await this.httpService.fetch(`${this.endpoint}api/v1/orders/${orderId}/refund_details`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (response.status !== 200) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        if (payload.errors) {
            throw new Error(`Backoffice has returned an error: ${payload.errors[0].message}`)
        }
        return payload
    }

    async refundOrder(
        accountSlug: string,
        orderUuid: string,
        selectedForRefundOrderItems: SelectedForRefund[],
        refundReason: string,
        forgoPartnerRefundFee: boolean,
        isCancellation: boolean,
    ): Promise<void> {
        const logEventType: EventType = 'order_refunded'
        const orderItems: SelectedForRefund[] = selectedForRefundOrderItems.filter((oi) => oi.ticketUuids.length)
        const logEventData: ActionEventData = {
            category: 'orders_transactions',
            payload: {
                orderUuid,
                orderItems,
                refundReason,
                forgoPartnerRefundFee,
                isCancellation,
            },
        }

        const response = await this.httpService.fetch(`${this.endpoint}api/v1/cart/refund/`, {
            method: 'POST',
            body: JSON.stringify({
                account: accountSlug,
                orderUuid,
                orderItems,
                refundReason,
                forgoPartnerRefundFee,
                isCancellation,
            }),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            let error = new Error(`Backoffice has returned status code: ${response.status}`)
            try {
                const body = await response.json()
                this.loggingService.logError(body, logEventType, logEventData)

                if (body.error) {
                    error = new Error(body.error.message)
                }
            } catch (jsonError) {
                this.loggingService.logResponseError(response, logEventType, logEventData)
            }
            throw error
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    bulkRefundOrder = async (options: BulkRefundOrderProps): Promise<BulkOrderRefundResponse> => {
        const logEventType: EventType = 'bulk_order_refunded'
        const logEventData: ActionEventData = {
            category: 'orders_transactions',
            payload: options,
        }

        const response = await this.httpService.fetch(`${this.endpoint}api/v1/cart/bulk_refund/`, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
        if (!response.ok) {
            try {
                const body = await response.json()
                this.loggingService.logError(body, logEventType, logEventData)

                return body
            } catch {
                this.loggingService.logResponseError(response, logEventType, logEventData)
                throw new Error(`Backoffice has returned status code: ${response.status}`)
            }
        }
        this.loggingService.logAction(logEventType, logEventData)

        return response.json()
    }

    async handleUploadError(response: Response) {
        const error = await response.json()
        if (error.errors.file.indexOf('invalid_image') > -1) {
            throw new Error('Uploaded file does not appear to be an image. Please try another file.')
        } else if (error.errors.file.indexOf('file_size') > -1) {
            throw new Error('Uploaded image is larger than 10MB. Please try a smaller image.')
        } else {
            throw new Error('An unknown error has occured. Please try again.')
        }
    }

    async uploadApfPhoto(file: File): Promise<UploadedFile> {
        const data = new FormData()
        data.append('file', file)
        data.append('crop_aspect_ratio', '0.78')
        const response = await this.httpService.fetch(`${this.endpoint}api/v1/cart/photo_upload/`, {
            method: 'POST',
            body: data,
        })
        if (response.status !== 200) {
            this.handleUploadError(response)
        }
        return await response.json()
    }

    async updateApfField(account: string, barcode: string, name: string, value: string): Promise<void> {
        const body = JSON.stringify({
            query: apfItemUpdateQuery,
            variables: { account, barcode, name, value },
        })

        const logEventType: EventType = 'order_apf_updated'
        const logEventData: ActionEventData = {
            category: 'orders_details',
            payload: { barcode, name, value },
        }

        const response = await this.httpService.fetch(`${this.endpoint}graphql`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        if (payload.errors || !payload.data.updateUserField.ok) {
            this.loggingService.logError(payload, logEventType, logEventData)
            throw new Error('Oops, something went wrong')
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async updateTicketValidToDate({
        account,
        validTo,
        orderItemId,
        orderItemUuid,
    }: {
        account: string
        orderItemId?: string
        validTo: string
        orderItemUuid?: string
    }): Promise<void> {
        const body = JSON.stringify({
            query: ticketValidToUpdateQuery,
            variables: { account, orderItemId, validTo, orderItemUuid },
        })

        const logEventType: EventType = 'order_item_valid_to_updated'
        const logEventData: ActionEventData = {
            category: 'orders_details',
            payload: { account, orderItemId, validTo },
        }

        const response = await this.httpService.fetch(`${this.endpoint}graphql`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        if (payload.errors || !payload.data.updateOrderItemValidTo.ok) {
            this.loggingService.logError(payload, logEventType, logEventData)
            throw new Error(
                payload.data.updateOrderItemValidTo.errorCode
                    ? payload.data.updateOrderItemValidTo.errorCode.split('_').join(' ')
                    : 'Oops, something went wrong',
            )
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async scheduleAsyncExport(
        endpoint: string,
        payload?: ScheduleAsyncExportPayload | UseDownloadLinkPayload | ExportReservationsPayload,
    ): Promise<void> {
        const params = parseSearch(`?${endpoint.split('?')[1]}`)
        const logEventType: EventType = 'order_async_export_requested'
        const logEventData: ActionEventData = {
            category: endpoint.indexOf('orders/details') > -1 ? 'orders_details' : 'orders_transactions',
            payload: {
                search: params.search,
                export_format: params.export_format,
                date_from: params.date_from,
                date_to: params.date_to,
                date_range_type: params.date_range_type,
                sort_by: params.sort_by,
                sort_direction: params.sort_direction,
                endpoint,
            },
        }

        const body = Object.fromEntries(
            Object.entries(payload || {}).filter(([key, value]) => value !== null && value !== ''),
        )

        const response = await this.httpService.fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(body),
        })

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async getProductListsWithArticles(accountSlug: string): Promise<AccountDataForFilter> {
        const query = `fragment productList on ProductsListType {
      uuid,
      name,
      articles {
        id,
        name
      }
    }
    query ProductLists($accountSlug: ID) {
      account(slug: $accountSlug) {
        locales {
          code
          name
        }
        locations {
          uuid
          name
        }
        productsLists {
          ${nestedProductListQueryFragment}
        }
      }
    }`
        const response = await this.httpService.fetch(`${this.endpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                operationName: 'ProductLists',
                query: query,
                variables: { accountSlug },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Backoffice service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.account
    }

    async getMinimalArticlesList(accountSlug: string): Promise<Article[]> {
        const query = `
      query ArticlesList($accountSlug: ID) {
        account(slug: $accountSlug) {
            articles(sortBy: name, sortDirection: asc) {
                id,
                name,
            }
        }
    }`
        const response = await this.httpService.fetch(`${this.endpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                operationName: 'ArticlesList',
                query: query,
                variables: { accountSlug },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Backoffice service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.account.articles
    }

    async listCarts({
        accountSlug,
        query,
    }: {
        accountSlug: string
        query?: string
    }): Promise<CursorPaginatedResponse<Cart[]>> {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/accounts/${accountSlug}/carts/?include=customer,urls,items.product_name,comment&is_pay_later_offer=true&${query}`,
        )

        return this.loggingService
            .handleResponse({
                response,
                logEventType: 'get_carts',
                logEventData: {
                    category: 'cart',
                    payload: { accountSlug, query },
                },
            })
            .catch(() => {
                throw new Error(`Backoffice has returned status code: ${response.status}`)
            })
    }

    async getCart({ accountSlug, uuid }: { accountSlug: string; uuid: string }): Promise<Cart> {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/accounts/${accountSlug}/carts/${uuid}?include=customer,urls,items.product_name,comment&is_pay_later_offer=true&is_expired=false`,
        )

        return this.loggingService
            .handleResponse({
                response,
                logEventType: 'get_cart',
                logEventData: {
                    category: 'cart',
                    payload: { accountSlug, uuid },
                },
            })
            .catch(() => {
                throw new Error(`Backoffice has returned status code: ${response.status}`)
            })
    }

    async patchCart({
        accountSlug,
        uuid,
        overrides: { id, ...overrides },
    }: {
        accountSlug: string
        uuid: string
        overrides: DeepPartial<Cart>
    }): Promise<CursorPaginatedResponse<Cart[]>> {
        const response = await this.httpService.fetch(`${this.endpoint}api/v1/accounts/${accountSlug}/carts/${uuid}/`, {
            method: 'PATCH',
            body: JSON.stringify({
                ...overrides,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return this.loggingService
            .handleResponse({
                response,
                logEventType: 'patch_cart',
                logEventData: {
                    category: 'cart',
                    payload: { accountSlug, uuid, overrides },
                },
            })
            .catch(() => {
                throw new Error(`Backoffice has returned status code: ${response.status}`)
            })
    }

    async patchOrder({
        uuid,
        overrides: { id, ...overrides },
    }: {
        uuid: string
        overrides: DeepPartial<IOrder>
    }): Promise<void> {
        const response = await this.httpService.fetch(`${this.endpoint}api/v1/orders/${uuid}/`, {
            method: 'PATCH',
            body: JSON.stringify({
                ...overrides,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return this.loggingService
            .handleResponse({
                response,
                logEventType: 'patch_order',
                logEventData: {
                    category: 'order_details_rest',
                    payload: { uuid, overrides },
                },
            })
            .catch(() => {
                throw new Error(`Backoffice has returned status code: ${response.status}`)
            })
    }

    async markCartAsPaid(accountSlug: string, cartId: string): Promise<void> {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/accounts/${accountSlug}/bookings/?send_confirmation_email=true`,
            {
                method: 'POST',
                body: JSON.stringify({
                    cartId,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        return this.loggingService.handleResponse<undefined>({
            response,
            logEventType: 'mark_cart_as_paid',
            logEventData: {
                category: 'cart',
                payload: { id: cartId, accountSlug },
            },
        })
    }

    async changeCartExpirationTime({
        accountSlug,
        id,
        expirationDate,
    }: {
        accountSlug: string
        id: string
        expirationDate: string
    }) {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/accounts/${accountSlug}/carts/${id}/expiration/`,
            {
                method: 'POST',
                body: JSON.stringify({
                    expiresAt: expirationDate,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        return this.loggingService.handleResponse<undefined>({
            response,
            logEventType: 'change_cart_expiration_date',
            logEventData: {
                category: 'cart',
                payload: { id, accountSlug, expirationDate },
            },
        })
    }

    async updateTicketApfField({
        ticketUuid,
        fieldName,
        fieldValue,
    }: {
        ticketUuid: string
        fieldName: string
        fieldValue: string
    }) {
        const logEventType: EventType = 'order_apf_updated'
        const logEventData: ActionEventData = {
            category: 'orders_details',
            payload: { ticketUuid, fieldName, fieldValue },
        }

        const response = await this.httpService.fetch(`${this.endpoint}api/v1/cart/tickets/${ticketUuid}/apf/`, {
            method: 'PUT',
            body: JSON.stringify({ field_name: fieldName, field_value: fieldValue }),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        return fieldValue
    }

    async cancelCart({ accountSlug, cartIds }: { accountSlug: string; cartIds: string[] }): Promise<void> {
        const response = await this.httpService.fetch(`${this.endpoint}api/v1/accounts/${accountSlug}/carts/cancel/`, {
            method: 'PATCH',
            body: JSON.stringify({
                cart_ids: cartIds,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return this.loggingService
            .handleResponse({
                response,
                logEventType: 'patch_carts',
                logEventData: {
                    category: 'cart',
                    payload: { accountSlug, cart_ids: cartIds },
                },
            })
            .catch(() => {
                throw new Error(`Backoffice has returned status code: ${response.status}`)
            })
    }

    async deleteComment({ accountSlug, commentId }: { accountSlug: string; commentId: string }): Promise<Cart> {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/accounts/${accountSlug}/comments/${commentId}`,
            {
                method: 'DELETE',
            },
        )

        return this.loggingService
            .handleResponse({
                response,
                logEventType: 'delete_comment',
                logEventData: {
                    category: 'cart',
                    payload: { accountSlug, commentId },
                },
            })
            .catch(() => {
                throw new Error(`Backoffice has returned status code: ${response.status}`)
            })
    }
}
